.btn-login {
	text-transform: uppercase;
	font-size: 18px;
	letter-spacing: 2px;
	margin-top: 12px;
	padding: 10px 20px;
	border-radius: 4px;
	border: 0;
	background: #00acf1;
	color: #fff;

	&:hover {
		background: #0091cb linear-gradient(180deg, #26a1d3, #0091cb) repeat-x;
		border-color: #0088be;
	}
}
