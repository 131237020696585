.card-img-left {
  border-radius: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.card-img-right {
  border-radius: 0;
  border-top-right-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}
.border-bottom-p{
  border-bottom: 1px solid black;
}